<template >
  <div>
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <div v-if="$route.params.type != 'deleted-task'">
        <b-button
          size="sm"
          :variant="
            task.task_status == 2 ? 'outline-success' : 'outline-secondary'
          "
          @click="markAsComplete()"
        >
          {{ task.task_status == 2 ? "Completed" : "Mark Complete" }}
        </b-button>
        <!-- edit_allowed false means the logged in user is the task assignor. Its just the naming is wrong -->
        <b-button
          size="sm"
          variant="outline-danger"
          v-if="edit_allowed"
          @click="deleteTask()"
          class="ml-1"
        >
          Delete
        </b-button>
      </div>

      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeSidebar()"
        />
      </div>
    </div>
    <b-card-code title="Update Task">
      <b-badge class="my-1" variant="light-primary">
        {{ task.task_type }}
      </b-badge>
      <h5>
        Asset:
        <span v-if="task.asset === null">{{ task.asset_name }}</span>
        <span v-else-if="task.asset_profile.is_deleted">{{
          task.asset_name
        }}</span>
        <router-link
          v-else
          :to="'/assets/scan/' + task.asset_profile.asset_id"
          >{{ task.asset_profile.asset_name }}</router-link
        >
      </h5>
      <h5>Assigned By: {{ task.task_assigned_by.full_name }}</h5>
      <h5
        v-if="
          task.vuln_id &&
          task.asset != null &&
          task.asset_profile != null &&
          !task.asset_profile.is_deleted
        "
      >
        Vulnerability:
        <router-link :to="  { path:'/vulnerability/detail/' + task.vuln_id, query: { isTaskDetails: true } }">
          Go to Details</router-link
        >
      </h5>
      <h5
        v-else-if="task.asset_profile != null && !task.asset_profile.is_deleted"
      >
        Vulnerability:
        <router-link :to="'/vulnerability/detail/' + task.vuln_id">
          Go to Details</router-link
        >
      </h5>
      <!-- form -->
      <validation-observer ref="OrgAddForm" #default="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent="updateTask">
          <!-- Name -->
          <b-form-group label="Task Title" label-for="Task Title">
            <template #label>
              <span>Task Title</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="task_title"
              rules="required"
            >
              <b-form-input
                id="task_title"
                v-model="task_title_local"
                :state="errors.length > 0 ? false : null"
                name="task_title"
                :disabled="!edit_allowed"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Task Description" label-for="Task Description">
            <b-form-textarea
              id="task_title"
              v-model="task_description_local"
              name="task_description"
              :disabled="!edit_allowed"
            />
          </b-form-group>
          <!-- Owner-->
          <b-form-group label="Assigned To" label-for="Assigned to:">
            <template #label>
              <span>Assigned To</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="task_assignee"
              rules="required"
            >
              <b-form-select
                v-model="task_assignee_local"
                :options="users"
                :state="errors.length > 0 ? false : null"
                :disabled="!reassignment_allowed"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Task Priority-->
          <b-form-group label="Priority" label-for="Priority">
            <template #label>
              <span>Priority</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="task_priority_local"
              rules="required"
            >
              <b-form-select
                v-model="task_priority_local"
                :options="task_priority_options"
                :state="errors.length > 0 ? false : null"
                :disabled="!reassignment_allowed"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Status" label-for="Status">
            <template #label>
              <span>Status</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="task_status"
              rules="required"
            >
              <b-form-select
                v-model="task_status_local"
                :options="task_statuses"
                :state="errors.length > 0 ? false : null"
                :disabled="!reassignment_allowed"
              ></b-form-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div
            v-if="
              task_status_local == 2 && task.task_type == 'Request_for_retest'
            "
          >
            <h6 class="text-secondary">
              Vulnerability Name:
              <b-badge class="my-1" variant="light-primary">
                {{ vuln_items }}
              </b-badge>
            </h6>
            <b-form-group label="Vulnerability Status" label-for="Status">
              <b-form-select
                @change="updateIssue()"
                v-model="vuln_status"
                :options="vuln_status_options"
              ></b-form-select>
            </b-form-group>
          </div>

          <b-button
            type="submit"
            variant="primary"
            :disabled="invalid"
            size="sm"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else> Update</span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-card-code>
    <TaskComments :task="task_o" />
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BBadge,
  BSidebar,
  BForm,
  BButton,
  VBToggle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TaskComments from "./components/comments.vue";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BBadge,
    BSidebar,
    VBToggle,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    TaskComments,
    BSpinner,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
    callBack: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      task_o: {},
      task_id: "",
      task_title_local: "",
      task_description_local: "",
      task_assignee_local: "",
      task_status_local: "",
      task_assignor_local: "",
      task_priority_local: 1,
      userId: localStorage.getItem("userid"),
      taskLocal: {},
      edit_allowed: false,
      reassignment_allowed: true,
      users: [{ value: null, text: "-- Assigned to --" }],
      task_statuses: [
        { value: 0, text: "Assigned" },
        { value: 1, text: "In Progress" },
        { value: 2, text: "Completed" },
        { value: 4, text: "Re-opened" },
      ],
      task_priority_options: [
        { value: 1, text: "Low" },
        { value: 2, text: "Medium" },
        { value: 3, text: "High" },
        { value: 4, text: "Critical" },
      ],
      isloading: false,
      vuln_status: "",
      vuln_status_options: [
        { value: 0, text: "Open" },
        { value: 1, text: "Closed" },
      ],
      vuln_items: "",
      organization: this.$store.state.app.org_id,
    };
  },
  watch: {
    task(value) {
      this.task_o = value;
      this.task_id = value.task_id;
      this.task_title_local = value.task_title;
      this.task_description_local = value.task_description;
      this.task_assignee_local = value.task_assignee;
      this.task_assignor_local = value.task_assignor;
      this.task_status_local = value.task_status;
      this.task_priority_local = value.task_priority;

      this.users = [{ value: null, text: "-- Assigned to --" }];
      this.reassignment_allowed = false;
      this.edit_allowed = false;
      if (localStorage.getItem("userid") == value.task_assignor) {
        this.edit_allowed = true;
        this.reassignment_allowed = true;
      }
      if (localStorage.getItem("userid") == value.task_assignee)
        this.reassignment_allowed = true;

      if (
        value.task_type == "Request_for_retest" &&
        localStorage.getItem("userid") != value.task_assignee
      ) {
        this.reassignment_allowed = false;
      }
      let url = process.env.VUE_APP_BASEURL + "user/users/get-all-users";
      if (this.is_staff) {
        url = url + "?q_org_id=" + this.organization;
      }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].id,
            text:
              res.data[key].full_name.charAt(0).toUpperCase() +
              res.data[key].full_name.slice(1),
          };
          self.users.push(user);
        });
      });

      //  vuln name
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.task.vuln_id +
          "/",
      };
      this.$http(options).then((res) => {
        this.vuln_items = res.data.name;
        this.vuln_status = res.data.status;
      });
    },
  },
  methods: {
    updateIssue: function (task, status) {
      let data = {
        status: this.vuln_status,
      };
      const options = {
        method: "PATCH",
        headers: { "content-type": "application/json" },
        data: data,
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.task.vuln_id +
          "/",
      };
      this.$http(options).then((res) => {
        this.callBack();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully updated",
            icon: "editIcon",
            variant: "success",
          },
        });
      });
    },
    updateTask: function () {
      this.isloading = true;
      if (this.task_assignee_local != this.task.task_assignee)
        this.task_assignor = localStorage.getItem("userid");
      let data = {
        task_title: this.task_title_local,
        task_description: this.task_description_local,
        task_assignee: this.task_assignee_local,
        task_status: this.task_status_local,
        task_priority: this.task_priority_local,
        task_assignor: this.task_assignor,
      };
      const options = {
        method: "PATCH",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "task/task/" + this.task_id + "/",
      };
      var self = this;
      if (this.edit_allowed || this.reassignment_allowed) {
        this.$http(options).then((res) => {
          if (res.data.task_id) {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully updated the task",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.isloading = false;
            self.callBack();
          }
        });
      }
    },
    markAsComplete: function () {
      this.task_status_local = 2;
      this.updateTask();
    },
    deleteTask: function () {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "task/task/" + this.task_id + "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        //if (res.data.task_id) {
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully deleted the task",
            icon: "EditIcon",
            variant: "success",
          },
        });
        self.callBack();
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
</style>
<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}
</style>