<template >
  <!-- <div class="w-100"> -->
  <b-card-code :title="vuln.name" class="w-100">
    <div
      class="d-flex justify-content-end"
      v-if="this.$route.query.isTaskDetails"
    >
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <Labels :vuln="vuln" />
    <!-- Edit Button -->
    <router-link :to="'/vulnerability/edit/' + vuln_id"
      ><b-button
        variant="primary"
        type="button"
        class="btn btn-primary float-right text-white"
        size="sm"
        ><span class="text-white">Edit Details</span></b-button
      ></router-link
    >
    <b-tabs v-model="detailTab" ref="btabs">
      <b-tab title="Details" active>
        <VulnActions
          :vuln="vuln"
          :callBack="load"
          :closeSidebar="load"
          :asset_id="asset_id"
          :organization="organization_id"
        />
      </b-tab>

      <b-tab title="Evidences" lazy>
        <b-tabs>
          <b-tab title="Tool Output" active>
            <div v-if="!requests.length && !nessus_evidence">
              <div
                class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
              >
                <feather-icon icon="DatabaseIcon" size="25" class="mb-50" />
                <h3 class="font-weight-bolder">No Data Found</h3>
              </div>
            </div>
            <div v-else>
              <div v-if="nessus_evidence">
                <h5 class="text-primary">Nessus Evidence</h5>
                <!-- <b-form-textarea
                    v-model="nessus_evidence"
                    class="border-primary"
                    rows="6"
                    max-rows="10"
                    disabled
                  >
                  </b-form-textarea> -->
                <p
                  v-html="nessus_evidence"
                  class="border-primary rounded p-1"
                ></p>
              </div>
              <div v-if="requests.length > 0" class="mt-1">
                <h5 class="text-primary">Burp Evidence</h5>
                <h6 class="text-primary">Requests</h6>
                <p v-for="reqt in requests" :key="reqt.request_type">
                  {{ reqt.request_type }}

                  <b-form-textarea
                    v-model="reqt.request_data"
                    class="border-primary"
                    rows="6"
                    max-rows="10"
                    disabled
                  >
                  </b-form-textarea>
                </p>
                <h6 class="text-primary">Responses</h6>
                <p v-for="resp in responses" :key="resp.response_data">
                  {{ resp.response_type }}
                  <b-form-textarea
                    v-model="resp.response_data"
                    class="border-primary"
                    rows="6"
                    max-rows="10"
                    disabled
                  >
                  </b-form-textarea>
                </p>
              </div>
            </div>
          </b-tab>
          <b-tab title="Steps to Reproduce Vulnerability" lazy>
            <div v-if="steps_to_reproduce.length">
              <b-list-group-item
                v-for="step in steps_to_reproduce"
                :key="step.filename"
                tag="li"
                class="my-1"
              >
                <StepBigImageViewCard :step="step" :steps_files="steps_files" />
              </b-list-group-item>
            </div>
            <div v-else>
              <div
                class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
              >
                <feather-icon icon="DatabaseIcon" size="25" class="mb-50" />
                <h3 class="font-weight-bolder">No Data Found</h3>
              </div>
            </div>
          </b-tab>

          <b-tab title="Retest Evidences" lazy>
            <!-- {{ retest_files }} -->
            <div v-if="retest_evidence.length">
              <b-list-group-item
                v-for="(listItem, index) in retest_evidence"
                :key="listItem.filename"
                tag="li"
                class="my-1"
              >
                <!-- <div class="row">
                  <div class="col-12 ml-25">
                    <b-form-textarea style="font-size: 17px"
                      class="mt-1 border-0 text-primary bg-transparent font-weight-bold" v-model="listItem.description"
                      :style="{ height: calculateTextareaHeight(listItem.description) + 'px' }"
                      disabled></b-form-textarea>
                    <div class="mt-1" v-if="!listItem.filename.includes('__no_file_')">
                      <img style="max-width: 100%" height="auto" :ref="listItem.filename"
                        :src="retest_files[listItem.filename]" :alt="listItem.caption" />
                      <div class="mt-1">
                        <h4 class="text-primary text-center">
                          {{ listItem.caption }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- <StepToReproduceCard :step="listItem" :steps_files="retest_files" :editSteps="null"
                :gotoDelete="null" :index="index+1" /> -->
                <StepBigImageViewCard
                  :step="listItem"
                  :steps_files="retest_files"
                  :index="index + 1"
                />
              </b-list-group-item>
            </div>

            <div v-else>
              <div
                class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
              >
                <feather-icon icon="DatabaseIcon" size="25" class="mb-50" />
                <h3 class="font-weight-bolder">No Data Found</h3>
              </div>
            </div>
          </b-tab>
          <b-tab title="Additional Evidences" lazy>
            <div v-if="attached_files">
              <b-list-group-item
                v-for="listItem in attached_files"
                :key="listItem.filename"
                tag="li"
                class="my-1"
              >
                <div class="row">
                  <!-- <b-avatar :text="listItem.filename.slice(0,2)" /> -->
                  <div
                    class="col-12 ml-25 w-100"
                    :style="{ maxHeight: '150px', overflowY: 'auto' }"
                  >
                    <!-- <b-form-textarea
                      class="text-primary border-0 bg-transparent"
                      v-model="listItem.description"
                      disabled
                    ></b-form-textarea> -->
                    <!-- <b-form-textarea
                      style="font-size: 17px"
                      class="border-0 text-primary bg-transparent font-weight-bold"
                      v-model="listItem.description"
                      :style="{
                        height:
                          calculateTextareaHeight(listItem.description) + 'px',
                      }"
                      disabled
                    ></b-form-textarea> -->
                    <h5
                      v-html="listItem.description"
                      class="border-0 text-primary bg-transparent font-weight-bold"
                    ></h5>
                    <!-- <div class="mt-1">
                      <img
                        style="max-width: 100%"
                        height="auto"
                        :ref="listItem.filename"
                        :src="images[listItem.filename]"
                        :alt="listItem.caption"
                      />
                      <div class="mt-1">
                        <h4 class="text-primary text-center">
                          {{ listItem.caption }}
                        </h4>
                      </div>
                    </div> -->
                  </div>
                  <div class="col-12 d-flex justify-content-between mt-1">
                    <div>
                      <b-card-text class="font-weight-bold mb-0 ml-1">
                        {{ listItem.filename }}
                      </b-card-text>
                    </div>
                    <small class="d-flex justify-content-end">
                      <b-button
                        size="sm mr-1"
                        variant="info"
                        @click="viewAditionalAttachment(listItem.filename)"
                      >
                        <span
                          v-if="
                            viewloader && listItem.filename == viewAditional
                          "
                          >Please wait</span
                        >
                        <span v-else>View</span>
                      </b-button>
                      <b-button
                        size="sm"
                        variant="primary"
                        @click="downloadAditionalAttachment(listItem.filename)"
                      >
                        <span
                          v-if="
                            isdownloader && listItem.filename == fileAditional
                          "
                          >Please wait</span
                        >
                        <span v-else>Download</span>
                      </b-button>
                    </small>
                    <!-- <img
                  v-bind:src="image_data"
                  v-if="image_data"
                  style="width: 100%"
                /> -->
                  </div>
                </div>
              </b-list-group-item>
              <b-card-code
                class="mt-1 card shadow-none bg-transparent border-primary"
                title="Preview"
                v-if="image_data || video_data || isviewloading"
              >
                <div
                  class="d-flex justify-content-center mb-1"
                  v-if="isviewloading"
                >
                  <b-spinner class="float-right" label="Floated Right" />
                </div>
                <img
                  v-bind:src="image_data"
                  v-if="image_data"
                  style="width: 100%"
                />
                <video width="100%" height="auto" v-if="video_data" controls>
                  <source v-bind:src="video_data" type="video/mp4" />
                </video>
              </b-card-code>
            </div>
            <div v-else>
              <div
                class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
              >
                <feather-icon icon="DatabaseIcon" size="25" class="mb-50" />
                <h3 class="font-weight-bolder">No Data Found</h3>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-tab>
      <b-tab title="Logs" lazy>
        <LogView :vuln_id="vuln_id" :activeTab="activeTab" />
      </b-tab>
      <b-tab title="Tasks" lazy>
        <Tasks
          :tasks="tasks"
          :callBack="load"
          :loading="false"
          :vuln_id="vuln_id"
          :organization="organization_id"
          :detailTab="detailTab"
        />
      </b-tab>
      <b-tab title="Discussions" lazy>
        <VulnComments :vuln_id="vuln_id" :organization="organization_id" />
      </b-tab>
      <b-tab
        title="Associated Vulnerabilities"
        v-if="vuln && vuln.child_vulns && vuln.child_vulns.length > 0"
      >
        <div
          v-if="vuln && vuln.child_vulns && vuln.child_vulns.length > 0"
          class="border-primary rounded p-1 mt-1"
        >
          <!-- <h5 class="text-primary">Associate Vulnerability</h5> -->
          <div class="custom-list">
            <ul
              v-for="vulnerability in vuln.child_vulns"
              :key="vulnerability.id"
            >
              <li>
                <a
                  @click="redirectToDetail(vulnerability.id)"
                  target="_blank"
                  >{{ vulnerability.name }}</a
                >
                <span class="ml-1">
                  <b-badge
                    variant="light-danger"
                    v-if="vulnerability.status == 0"
                    >Open</b-badge
                  >
                  <b-badge
                    variant="light-success"
                    v-if="vulnerability.status == 1"
                    >Closed</b-badge
                  >
                  <b-badge
                    variant="light-warning"
                    v-if="vulnerability.status == 2"
                    >False Positive</b-badge
                  >
                  <b-badge
                    variant="light-success"
                    v-if="vulnerability.status == 3"
                    >Accepted</b-badge
                  >
                  <b-badge
                    variant="light-warning"
                    v-if="vulnerability.status == 4"
                    >Assigned</b-badge
                  >
                  <b-badge
                    variant="light-warning"
                    v-if="vulnerability.status == 5"
                    >Mitigated</b-badge
                  >
                  <b-badge
                    variant="light-danger"
                    v-if="vulnerability.status == 9"
                    >Deleted</b-badge
                  >
                </span>
              </li>
            </ul>
          </div>
        </div>
      </b-tab>
      <b-tab title="Parent Vulnerability" v-if="vuln.parent_vuln != null">
        <div
          v-if="vuln.parent_vuln != null"
          class="border-primary rounded p-1 mt-1"
        >
          <!-- <h5 class="text-primary">Parent Vulnerability</h5> -->
          <p>
            <a @click="redirectToDetail(vuln.parent_id)" target="_blank">{{
              vuln.parent_vuln.name
            }}</a>
          </p>
        </div>
      </b-tab>
      <b-tab title="CVE MetaData">
        <CVEMetaData :cveMetaData="cveMetaData" :detailTab="detailTab" />
      </b-tab>
    </b-tabs>
  </b-card-code>
  <!-- </div> -->
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import { Mentionable } from "vue-mention";
import axios from "axios";

import {
  BTabs,
  BTab,
  BModal,
  BPagination,
  BPaginationNav,
  BBadge,
  BSidebar,
  BForm,
  BButton,
  VBToggle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BListGroupItem,
  BCardText,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CVEMetaData from "./components/cveMetaData.vue";
import LogView from "./components/LogView.vue";
import VulnActions from "./components/VulnActions.vue";
import VulnComments from "./components/Comments.vue";
import Tasks from "./../Task/components/tasks.vue";
import Labels from "./components/Labels.vue";
import StepToReproduceCard from "./components/StepToReproduceCard.vue";
import StepBigImageViewCard from "./components/StepBigImageViewCard.vue";
export default {
  components: {
    BTabs,
    BTab,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BBadge,
    BSidebar,
    VBToggle,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BModal,
    BListGroupItem,
    BCardText,
    LogView,
    BSpinner,
    VulnActions,
    VulnComments,
    Tasks,
    Labels,
    Mentionable,
    StepToReproduceCard,
    StepBigImageViewCard,
    CVEMetaData,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  // model: {
  //   prop: 'isTaskHandlerSidebarActive',
  //   event: 'update:is-task-handler-sidebar-active',
  // },
  data() {
    return {
      vuln: null,
      asset_id: null,
      organization: null,
      attached_files: [],
      vuln_id: parseInt(this.$route.params.id),
      image_data: false,
      video_data: false,
      loading: false,
      vuln_doc: null,
      asset_id: null,
      organization_id: this.$store.state.app.org_id,
      // organization_id: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      tasks: [],
      nessus_evidence: "-------",
      burp_evidence: [],
      requests: [],
      responses: [],
      images: {},
      loader: "",
      viewloader: false,

      imageSrc: null,
      steps_to_reproduce: [],
      steps_files: {},
      retest_evidence: [],
      retest_files: {},
      isdownloader: false,
      isviewloading: false,

      participants: [],
      cveMetaData: [],
      detailTab: 0,
      activeTab: 1,
    };
  },

  mounted: function () {
    this.load();
  },
  watch: {
    $route(to, from) {
      this.load();
    },
    detailTab(newValue, oldValue) {
      if (newValue === 3) {
        this.loadTasks();
      }
      const tabs = this.$refs.btabs.tabs;
      if (tabs.length > 0 && newValue === tabs.length - 1) {
        this.loadCVE();
      }
    },
  },

  methods: {
    // numberOfRows(content) {
    //   const lines = content.split(/\r\n|\r|\n/);
    //   return lines.length;
    // },
    redirectToDetail(id) {
      this.$router.push(`/vulnerability/detail/${id}`);
    },
    calculateTextareaHeight(content) {
      const lineHeight = 35; // adjust as needed to match your CSS
      const lines = content.split(/\r\n|\r|\n/);
      const height = lines.length * lineHeight;
      return height;
    },
    tagedUsers(value) {
      const tagedUserlist = [];
      console.log("mentioned userid" + value.id);
      this.tagedUserlist.push(value.id);
    },
    getMention: function (event) {
      //  console.log(event)

      if (event.keyCode === 50) {
        this.getUsers();
        // alert('@ was pressed');
        // this.getUsers();
      }
      // this.log += e.key;
    },
    viewAttachment(filename) {
      const options = {
        method: "GET",
        data: { filename: filename },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/vuln-details/",
        responseType: "bolb",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(
          res.data,
          "res imagehhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh"
        );
        this.image_data = window.URL.createObjectURL(res.data);
        const base64 = new TextEncoder().encode(response.data);
        this.imageSrc = `data:image/jpeg;base64,${base64}`;
      });
    },

    load: function () {
      this.burp_evidence = [];
      this.requests = [];
      this.responses = [];
      this.nessus_evidence = "-------";
      this.evidence = [];

      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/vuln-details/",
      };
      var self = this;
      // fetch attachments
      this.$http(o_options).then((res) => {
        // console.log(res.data)
        // var data = JSON.parse(res.data);
        var data = res.data;
        if (typeof data === "string") {
          data = JSON.parse(data);
        }
        // console.log("V-Data", data.steps_to_reproduce);
        let key = data;
        // if (key["additional_evidence"]) {
        //   var f_data = JSON.parse(key["additional_evidence"]);
        //   console.log(f_data);
        //   // self.attached_files = f_data;
        //   Object.keys(f_data).map(function (filename, filedetails) {
        //     console.log("filenam", filename);
        //     self.downloadAttachment(filename, "additional_evidence");
        //   });
        // }
        if (key["steps_to_reproduce"]) {
          var f_data = JSON.parse(key["steps_to_reproduce"]);
          console.log(f_data);
          // f_data.map(function(filename, filedetails) {
          const new_steps_to_reproduce = [];
          Object.values(f_data).map((data) => {
            new_steps_to_reproduce[data.order_id - 1] = data;
          });
          self.steps_to_reproduce = new_steps_to_reproduce;
          Object.keys(f_data).map(function (filename, filedetails) {
            console.log("filenam", filename);
            self.downloadAttachment(filename, "steps_to_reproduce");
          });
        }
        if (key["retest_evidence"]) {
          var f_data = JSON.parse(key["retest_evidence"]);
          console.log(f_data);
          // f_data.map(function(filename, filedetails) {
          self.retest_evidence = Object.values(f_data);
          Object.keys(f_data).map(function (filename) {
            console.log("filenam", filename);
            self.downloadAttachment(filename, "retest_evidence");
          });
        }
      });
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "asset/vulns/" + this.$route.params.id,
        //"/vuln-details/",
      }).then((res) => {
        console.log(res);
        // var data = JSON.parse(res.data);
        self.vuln = res.data;
        self.nessus_evidence = res.data.nessus_evidence;
        self.asset_id = self.vuln.asset_id;
        self.organization_id = self.vuln.org_id;
        if (self.vuln.additional_evidence) {
          var f_data = JSON.parse(self.vuln.additional_evidence);
          console.log(f_data);
          // f_data.map(function(filename, filedetails) {
          self.attached_files = f_data;
          Object.keys(f_data).map(function (filename, filedetails) {
            console.log("filenam", filename);
            // self.downloadAttachment(filename);
          });
        }
        self.burp_evidence = res.data.burp_evidence;
        if (res.data.burp_evidence) {
          res.data.burp_evidence.map(function (k, v) {
            k["request_response"]["request"].map(function (r, v) {
              let req = {
                request_type: r["type"],
                request_data: atob(r["data"]),
              };
              self.requests.push(req);
            });
            k["request_response"]["response"].map(function (a, b) {
              let res = {
                response_type: a["type"],
                // response_data:self.fromBinary(a['data'])
                response_data: atob(a["data"]),
              };
              self.responses.push(res);
            });
          });
        }
      });
      // Load Tasks
      // if(this.detailTab === 3){
      //   debugger
      //   const options = {
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   url:
      //     process.env.VUE_APP_BASEURL +
      //     "task/task/vuln-tasks?vuln_id=" +
      //     this.$route.params.id,
      // };
      // this.$http(options).then((res) => {
      //   this.tasks = res.data.results;
      // });
      // }
    },
    goBack() {
      this.$router.go(-1);
    },
    loadCVE() {
      // meta data
      const options_cve = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/cve-meta-data/",
      };
      this.$http(options_cve).then((res) => {
        this.cveMetaData = res.data;
      });
    },

    loadTasks() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "task/task/vuln-tasks?vuln_id=" +
          this.$route.params.id,
      };
      this.$http(options).then((res) => {
        this.tasks = res.data.results;
      });
    },

    downloadAttachment(filename, key) {
      this.loading = true;
      this.loader = true;
      var url;
      if (!filename.includes("__no_file_"))
        url =
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/download-attachment/";

      if (key == "steps_to_reproduce" && !filename.includes("__no_file_"))
        url =
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/download-steps-to-reproduce-evidences/";

      if (key == "retest_evidence" && !filename.includes("__no_file_"))
        url =
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/download-retest_evidence/";

      const options = {
        method: "POST",
        data: { filename: filename },
        url: url,
        responseType: "blob",
      };
      var self = this;
      this.$http(options).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        // this.images.filename = url;
        // this.$refs[filename].src = url
        if (key == "steps_to_reproduce" && !filename.includes("__no_file_"))
          this.$set(self.steps_files, filename, url);
        if (key == "retest_evidence" && !filename.includes("__no_file_"))
          this.$set(self.retest_files, filename, url);
        // self.images.push({[filename]:url})
        // this.loading = false;
        // const url = window.URL.createObjectURL(new Blob([res.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", filename); //or any other extension
        // document.body.appendChild(link);
        // link.click();
        // this.loader = false;
      });
    },

    downloadAditionalAttachment(filename) {
      this.isviewloading = true;
      this.isdownloader = true;
      this.fileAditional = filename;
      const options = {
        method: "POST",
        data: { filename: filename },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/download-attachment/",
        responseType: "blob",
      };
      var self = this;
      this.$http(options).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.isviewloading = false;
        this.isdownloader = false;
      });
    },

    viewAditionalAttachment(filename) {
      this.isviewloading = true;
      this.viewloader = true;
      this.viewAditional = filename;
      this.video_data = this.image_data = false;
      const options = {
        method: "POST",
        data: { filename: filename },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.$route.params.id +
          "/download-attachment/",
        responseType: "blob",
      };
      var self = this;
      this.$http(options).then((res) => {
        //this.image_data = new Blob([res.data])
        var ext = filename.split(".").pop();
        console.log(ext);
        if (ext == "mp4")
          this.video_data = window.URL.createObjectURL(new Blob([res.data]));
        else if (ext == "jpeg" || ext == "jpg" || ext == "png")
          this.image_data = window.URL.createObjectURL(new Blob([res.data]));
        else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        this.isviewloading = false;
        this.viewloader = false;
      });
    },
  },
};
</script>
<style lang="css">
.b-form-textarea {
  resize: vertical !important;
}

/* .ta {
  resize: vertical;
} */
.b-sidebar.sidebar-lg {
  width: 60rem;
}

.todo-application .content-area-wrapper .content-right {
  width: 100% !important;
}

.content-area-wrapper {
  height: 100% !important;
}
</style>
<style scoped>
[dir] .list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: rgba(245, 231, 231, 0);
  border-radius: 5px !important;
  border: 2px solid rgb(45 95 138 / 13%);
}

[dir].dark-layout .list-group-item {
  padding: 0.75rem 1.25rem;
  border-radius: 5px !important;
  background-color: #05050500 !important;
  border: 2px solid rgb(45 95 138 / 13%);
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

[dir].dark-layout .list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: #283046 !important;
  border: 1px solid rgb(68 101 129) !important;
  /* border-radius: 5px; */
}
</style>