<template>
  <div>
    <span v-for="item in vuln.labels" :key="item.id">
      <b-badge variant="info" class="mr-1"
        >{{ item }}
        <feather-icon icon="XCircleIcon" @click="detachLabel(item)" />
      </b-badge>
    </span>

    <b-button
      variant="flat-info"
      title="Add New Label"
      class="btn-icon"
      @click="openLabelModal()"
    >
      Add Label <feather-icon icon="PlusIcon" />
    </b-button>
    <b-modal ref="modal-add-label" title="Add Label" hide-footer>
      <b-form-group label="Name" label-for="Name">
        <v-select
          v-model="label_selected"
          taggable
          label="text"
          :options="label_options"
          @search="fetchLabels"
          @option:selected="saveLabel"
          autocomplete
          aria-placeholder="Add Label"
        />
      </b-form-group>
      <!-- <b-button size="sm" variant="primary" @click="saveLabel">
        Add
      </b-button> -->
    </b-modal>
  </div>
</template>
<script>
import vSelect from "vue-select";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BBadge,
  BSidebar,
  BForm,
  BButton,
  VBToggle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BRow,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BModal,
    BPagination,
    BPaginationNav,
    BBadge,
    BSidebar,
    BForm,
    BButton,
    VBToggle,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BRow,
    vSelect,
  },
  props: {
    vuln: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      labels: [],
      label_selected: null,
      label_options: [],
    };
  },
  methods: {
    openLabelModal: function () {
      // this.task_title = "Please close port "+port+" of asset "+ip
      this.$refs["modal-add-label"].show();
    },
    fetchLabels: function (search, loading) {
      if (search.length) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/vuln-label?query=true" +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.label_options = [];
          res.data.map(function (value, key) {
            let a = {
              value: value.text,
              text: value.text,
              full_details: value,
            };
            // console.log(res.data[key].profile.organization)
            self.label_options.push(a);
          });
        });
      }
    },
    saveLabel: function (selectedOption) {
      // let is_string = Object.prototype.toString.call(selectedOption) === '[object Array]'
      // if (is_string){
      console.log(selectedOption);
      let t = "";
      if (selectedOption.text == null) t = selectedOption;
      else t = selectedOption.text;
      let data = {
        text: t,
        // org_id: 2,
      };
      const a_options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vuln-label/?vuln_id=" +
          this.vuln.id, //+
        // "&name=" +
        // search,
      };
      var self = this;
      this.$http(a_options).then((res) => {
        self.label_options = [];
        self.label_selected = null;
        if (self.vuln.labels instanceof Array) self.vuln.labels.push(t);
        else self.vuln.labels = [t];

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully added the Label",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
      // }else{
      //   alert(Object.prototype.toString.call(selectedOption))
      // }
    },
    detachLabel: function (selected) {
      let data = {
        label: selected,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          this.vuln.id +
          "/detach-label/",
      };
      this.$http(options).then((res) => {
        //if(res.data.status=="success")

        const index = this.vuln.labels.indexOf(selected);
        if (index > -1) {
          this.vuln.labels.splice(index, 1); // 2nd parameter means remove one item only
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: "EditIcon",
            variant: res.data.status,
          },
        });
      });
    },
  },
};
</script>