<template >
  <div>
    <div
      class="d-flex justify-content-end align-items-center content-sidebar-header px-2 py-1"
    >
      <div class="">
        <b-badge class="mr-1" v-if="vuln.is_verified" variant="success"
          >Verified</b-badge
        >
        <b-badge class="mr-1" v-else variant="danger">Unverified</b-badge>

        <b-badge class="mr-1" v-if="vuln.status == 0" variant="danger"
          >Open</b-badge
        >
        <b-badge class="mr-1" v-if="vuln.status == 1" variant="success"
          >Closed</b-badge
        >
        <b-badge class="mr-1" v-if="vuln.status == 2" variant="warning"
          >False Positive</b-badge
        >
        <b-dropdown
          size="sm"
          class="mr-1"
          variant="outline-primary"
          text="Actions"
        >
          <b-dropdown-item @click="editIssue(vuln)">Edit</b-dropdown-item>
          <b-dropdown-item
            v-if="vuln.status == 0"
            @click="doBulkAction(vuln, 1)"
            >Mark as closed</b-dropdown-item
          >
          <b-dropdown-item
            v-if="vuln.status == 0"
            @click="doBulkAction(vuln, 3)"
            >Accept the risk</b-dropdown-item
          >
          <b-dropdown-item
            v-if="vuln.status != 0"
            @click="doBulkAction(vuln, 0)"
            >Re-open</b-dropdown-item
          >
          <b-dropdown-item
            v-if="vuln.status == 0"
            @click="doBulkAction(vuln, 2)"
            >Mark as False Positive</b-dropdown-item
          >
          <b-dropdown-item @click="OpenTaskModal()">Mitigate</b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            v-if="vuln.is_verified && this.$store.state.app.user.is_staff"
            @click="changeverificationstatus(vuln, 0)"
            >Mark as un-verified</b-dropdown-item
          >
          <b-dropdown-item
            v-else-if="!vuln.is_verified && this.$store.state.app.user.is_staff"
            @click="changeverificationstatus(vuln, 1)"
            >Mark as verified</b-dropdown-item
          >
          <b-dropdown-item @click="deleteIssue(vuln)">Delete</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <b-card-code>
      <!-- <router-link
          :to="'/vulnerability/detail/' + vuln.vuln_id"
          ><b-button variant="primary" type="button" class="btn btn-sm btn-primary float-right text-white "><span class="text-white">More Details</span></b-button></router-link
        > -->
      <div class="row">
        <!-- <div class="col-3">
          <b-card v-if="vuln.asset" class="border-primary">
            <h5 class="text-primary">Asset:</h5>
            <span>
              <router-link :to="'/assets/scan/' + vuln.asset.asset_id">{{
                vuln.asset.asset_name
              }}</router-link></span
            >
          </b-card>
        </div>
        <div class="col-3">
          <b-card class="border-primary">
            <h5 class="text-primary">Reported At:</h5>
            <span class="text-secondary">{{
              vuln.created_at | formatdate
            }}</span>
          </b-card>
        </div>

        <div class="col-3">
          <b-card class="border-primary">
            <h5 class="text-primary">Last Updated At:</h5>
            <span class="text-secondary">{{
              vuln.updated_at | formatdate
            }}</span>
          </b-card>
        </div>

        <div class="col-3">
          <b-card class="border-primary">
            <h5 class="text-primary">Port:</h5>
            <span class="text-secondary">{{ vuln.port }}</span>
          </b-card>
        </div> -->

        <div class="col-12">
          <b-card align="center" class="bg-dark-blue h-100">
            <div class="row">
              <div class="col">
                <b-list-group
                  class="list-group-circle text-left bg-transparent"
                >
                  <b-list-group-item class="bg-transparent">
                    <b>Asset:</b>
                    <span>
                      <router-link :to="'/assets/scan/' + vuln.asset.asset_id">
                        {{ vuln.asset.asset_name }}</router-link
                      ></span
                    >
                  </b-list-group-item>
                  <b-list-group-item
                    class="bg-transparent d-flex align-item-center"
                  >
                    <b>Severity:</b>
                    <span class="w-100 ml-1"
                      ><b-form-select
                        size="sm"
                        class="w-100"
                        @change="updateSeverity(vuln)"
                        v-model="vuln.severity"
                        :options="severity_options"
                      ></b-form-select
                    ></span>
                  </b-list-group-item>
                  <b-list-group-item class="bg-transparent">
                    <b>CVSS Score:</b>
                    <span class=""> {{ vuln.cvss_score }}</span>
                  </b-list-group-item>
                  <b-list-group-item class="bg-transparent">
                    <b>CVE:</b> <span class=""> {{ vuln.cve }}</span>
                  </b-list-group-item>
                  <b-list-group-item class="bg-transparent">
                    <b class="mr-1">Remediation Priority:</b>
                    <!-- <span class="text-secondary">{{ vuln.parameter }}</span> -->
                    <b-badge
                      style="background-color: #991d28"
                      v-if="vuln.cve_meta_data == 1"
                      >Critical</b-badge
                    >
                    <b-badge variant="danger" v-if="vuln.cve_meta_data == 2"
                      >High</b-badge
                    >
                    <b-badge variant="warning" v-if="vuln.cve_meta_data == 3"
                      >Medium</b-badge
                    >
                    <b-badge variant="success" v-if="vuln.cve_meta_data == 4"
                      >Low</b-badge
                    >
                    <b-badge variant="info" v-if="vuln.cve_meta_data == 5"
                      >Very Low</b-badge
                    >
                    <b-badge
                      variant="light-danger"
                      v-if="vuln.cve_meta_data == null"
                      >NA</b-badge
                    >
                  </b-list-group-item>
                </b-list-group>
              </div>
              <div class="col">
                <b-list-group
                  class="list-group-circle text-left bg-transparent"
                >
                  <b-list-group-item class="bg-transparent">
                    <b>Owasp Categories:</b>
                    <span class="">
                      <li
                        v-for="item in vuln.owasp_categories"
                        :key="item.value"
                      >
                        {{ item.value }}
                      </li>
                    </span>
                  </b-list-group-item>
                  <b-list-group-item class="bg-transparent">
                    <b>Path:</b> <span class=""> {{ vuln.path }}</span>
                  </b-list-group-item>
                  <b-list-group-item class="bg-transparent">
                    <b>Protocol:</b><span class=""> {{ vuln.protocol }}</span>
                  </b-list-group-item>
                  <b-list-group-item class="bg-transparent">
                    <b>Port:</b><span class=""> {{ vuln.port }}</span>
                  </b-list-group-item>
                  <b-list-group-item class="bg-transparent">
                    <b class="mr-1">CISA KEV:</b>
                    <b-badge variant="success" v-if="vuln.cisa_kev == true"
                      >Yes</b-badge
                    >
                    <b-badge variant="danger" v-if="vuln.cisa_kev == false"
                      >No</b-badge
                    >
                  </b-list-group-item>
                </b-list-group>
              </div>
              <div class="col">
                <b-list-group
                  class="list-group-circle text-left bg-transparent"
                >
                  <b-list-group-item class="bg-transparent">
                    <b>Parameter:</b>
                    <span class=""> {{ vuln.parameter }}</span>
                  </b-list-group-item>
                  <b-list-group-item class="bg-transparent">
                    <b>Reported At:</b>
                    <span class=""> {{ vuln.created_at | formatdate }}</span>
                  </b-list-group-item>
                  <b-list-group-item class="bg-transparent">
                    <b>Last Updated At:</b>
                    <span class=""> {{ vuln.updated_at | formatdate }}</span>
                  </b-list-group-item>
                  <b-list-group-item class="bg-transparent">
                    <b> Source:</b>
                    <span class=""> {{ vuln.source }}</span>
                  </b-list-group-item>
                  <b-list-group-item class="bg-transparent">
                    <b class="mr-1">EPSS:</b>
                    <span>{{ vuln.epss }}</span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
          </b-card>
        </div>
      </div>
      <div class="mt-1">
        <h5 class="text-primary">Synopsis</h5>
        <p v-html="synopsis"></p>
      </div>

      <div class="mt-1">
        <h5 class="text-primary">Description</h5>
        <p v-html="description"></p>
      </div>

      <!-- <div>
        <h5 class="text-primary">Recommendations</h5>
        <p v-html="references">{{ vuln.recommendation }}</p>
      </div> -->
      <div>
        <h5 class="text-primary">References</h5>
        <p v-html="references"></p>
      </div>
      <!-- <div v-if="nessus_evidence">
        <h5 class="text-primary">Nessus Evidence</h5>
        <b-form-textarea
          v-model="nessus_evidence"
          class="border-primary"
          rows="6"
          max-rows="10"
          disabled
        >
        </b-form-textarea>
      </div> -->
      <div>
        <h5 class="text-primary">Recommendations</h5>
        <!-- <b-form-textarea
          v-model="recommendation"
          class="border-primary"
          rows="6"
          max-rows="10"
          @change="updateRemediation(vuln.id)"
          disabled
        ></b-form-textarea> -->
        <p v-html="recommendation"></p>
      </div>
      <div v-if="requests.length > 0">
        <h5 class="text-primary">Burp Evidence</h5>
        <h6 class="text-primary">Requests</h6>
        <p v-for="reqt in requests" :key="reqt.request_type">
          {{ reqt.request_type }}

          <b-form-textarea
            v-model="reqt.request_data"
            class="border-primary"
            rows="6"
            max-rows="10"
            disabled
          >
          </b-form-textarea>
        </p>
        <h6 class="text-primary">Responses</h6>
        <p v-for="resp in responses" :key="resp.response_data">
          {{ resp.response_type }}
          <b-form-textarea
            v-model="resp.response_data"
            class="border-primary"
            rows="6"
            max-rows="10"
            disabled
          >
          </b-form-textarea>
        </p>
      </div>

      <b-modal
        ref="modal-mitigate-vuln"
        title="Add Task"
        hide-footer
        size="lg"
        no-close-on-backdrop
      >
        <p class="my-1">Add a task to mitigate this issue!</p>
        <TaskAddForm
          :asset_id="asset_id"
          :organization="organization_id"
          :callBack="afterTaskAdd"
          :task="vuln.name"
          :description="recommendation"
          task_type="WEB_APP_VULNERABILITY"
          :vuln_id="vuln.id"
          :vuln="vuln"
        />
      </b-modal>
    </b-card-code>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BCard,
  BModal,
  BPagination,
  BPaginationNav,
  BBadge,
  BSidebar,
  BForm,
  BButton,
  VBToggle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TaskAddForm from "../../Asset/components/TaskAddForm.vue";

import moment from "moment";

export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BBadge,
    BSidebar,
    VBToggle,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BModal,
    TaskAddForm,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  // model: {
  //   prop: 'isTaskHandlerSidebarActive',
  //   event: 'update:is-task-handler-sidebar-active',
  // },
  props: {
    vuln: {
      type: Object,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
    callBack: {
      type: Function,
      required: true,
    },
    asset_id: {
      type: Number,
      required: true,
    },
    organization: {
      type: Number,
      required: true,
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  data() {
    return {
      description: "-------",
      synopsis: "-------",
      owasp_categories: "",
      cvss_score: "",
      cve: "",
      path: "",
      origin: "",
      recommendation: "-------",
      nessus_evidence: "-------",
      burp_evidence: [],
      requests: [],
      responses: [],
      severity_options: [
        { value: "critical", text: "Critical" },
        { value: "high", text: "High" },
        { value: "medium", text: "Medium" },
        { value: "low", text: "Low" },
        { value: "info", text: "Info" },
      ],
      references: "-------",
      organization_id: this.$store.state.app.org_id,
    };
  },
  created: function () {
    this.burp_evidence = [];
    this.requests = [];
    this.responses = [];
    this.description = "-------";
    this.synopsis = "-------";
    this.recommendation = "-------";
    this.references = "-------";
    this.nessus_evidence = "-------";
    const o_options = {
      method: "GET",
      headers: { "content-type": "application/json" },
      url: process.env.VUE_APP_BASEURL + "asset/vulns/" + this.$route.params.id, //+'/vuln-details/',
    };
    var self = this;
    this.$http(o_options).then((res) => {
      console.log("New respoense", res.data);
      self.description = res.data.description;
      self.synopsis = res.data.synopsis;
      self.recommendation = res.data.recommendation;
      self.nessus_evidence = res.data.nessus_evidence;
      self.references = res.data.references;
      self.cvss_score = res.data.cvss_score;
      self.cve = res.data.cve;
      self.owasp_categories = res.data.owasp_categories;
      self.burp_evidence = res.data.burp_evidence;

      if (res.data.burp_evidence) {
        res.data.burp_evidence.map(function (k, v) {
          k["request_response"]["request"].map(function (r, v) {
            let req = {
              request_type: r["type"],
              request_data: atob(r["data"]),
            };
            self.requests.push(req);
          });
          k["request_response"]["response"].map(function (a, b) {
            let res = {
              response_type: a["type"],
              // response_data:self.fromBinary(a['data'])
              response_data: atob(a["data"]),
            };
            self.responses.push(res);
          });
        });
      }
    });
  },
  //   watch: {
  //         vuln(value) {

  //         }
  //   },
  // created: function () {
  //    this.load();
  // },
  methods: {
    fromBinary: function (encoded) {
      return decodeURIComponent(
        atob(encoded)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
    },
    updateRemediation: function (id) {
      let data = {
        recommendation: this.recommendation,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/" +
          id +
          "/update-details/",
      };
      this.$http(options).then((res) => {
        this.callBack();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully updated",
            icon: "editIcon",
            variant: "success",
          },
        });
      });
    },

    // currently not using this api because of mongo not supporting patch method instead of this using doBulkAction()
    updateIssue: function (vuln, status) {
      let data = {
        status: status,
        severity: this.severity,
      };
      const options = {
        method: "PATCH",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "asset/vulns/" + vuln.id + "/",
      };
      this.$http(options).then((res) => {
        this.callBack();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully updated",
            icon: "editIcon",
            variant: "success",
          },
        });
      });
      // }
    },
    doBulkAction: function (vuln, status) {
      var self = this;
      this.loading = true;
      let data = {
        status: status,
        vulnerabilties: [this.vuln.id],
      };

      this.$http({
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vulns/update-vulnerability-in-bulk/",
      }).then((res) => {
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedVulns = [];
          self.searchFn(false);
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.loading = false;
      });
    },
    updateSeverity: function (vuln) {
      //if(confirm("Do you really want to mark this as false positive?")){
      let data = {
        severity: vuln.severity,
      };
      const options = {
        method: "PATCH",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "asset/vulns/" + vuln.id + "/",
      };
      this.$http(options).then((res) => {
        this.callBack();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully updated",
            icon: "editIcon",
            variant: "success",
          },
        });
      });
      // }
    },
    changeverificationstatus: function (vuln, value) {
      let verified_by = "";
      if (value) verified_by = this.$store.state.app.user.email;

      let data = {
        is_verified: value,
        verified_by: verified_by,
      };
      const options = {
        method: "PATCH",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "asset/vulns/" + vuln.id + "/",
      };
      this.$http(options).then((res) => {
        this.callBack();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully changed",
            icon: "editIcon",
            variant: "success",
          },
        });
        this.closeSidebar();
      });
    },
    deleteIssue: function (vuln) {
      let data = {
        status: status,
      };
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "asset/vulns/" + vuln.id + "/",
      };
      this.$http(options).then((res) => {
        // this.callBack()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully deleted",
            icon: "trashIcon",
            variant: "success",
          },
        });
        // this.closeSidebar()
        this.$router.push("/assets/vulnerabilities");
        this.load();
      });
    },
    editIssue: function (vuln) {
      this.$router.push({
        name: "Edit Vulnerability",
        params: { id: vuln.id },
      });
    },
    afterTaskAdd: function () {
      this.$refs["modal-mitigate-vuln"].hide();
    },
    OpenTaskModal: function () {
      // this.task_title = "Please close port "+port+" of asset "+ip
      this.$refs["modal-mitigate-vuln"].show();
    },
  },
};
</script>
<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}
.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #131721 !important;
  color: #fff;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
<style lang="scss" scoped>
[dir].dark-layout .list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: #283046 !important;
  border: 1px solid rgb(68 101 129) !important;
  /* border-radius: 5px; */
}
@import "~@core/scss/base/bootstrap-extended/include";
</style>
<style scoped>
.custom-list {
  min-height: 150px;
  max-height: 100px;
  overflow-y: auto;
}
.custom-list li:hover {
  color: #7367f0; /* Change the text color to blue on hover */
}
</style>