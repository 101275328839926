var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"AssetEditForm"},[_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.addTask($event)}}},[_c('b-form-group',{attrs:{"label":"Task Title","label-for":"Task Title"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Task Title")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"task_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"task_title","state":errors.length > 0 ? false : null},model:{value:(_vm.task_title),callback:function ($$v) {_vm.task_title=$$v},expression:"task_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Task Description","label-for":"Task Description"}},[_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.task_description),callback:function ($$v) {_vm.task_description=$$v},expression:"task_description"}},[_c('div',{attrs:{"slot":"toolbar","id":"toolbar"},slot:"toolbar"},[_c('button',{staticClass:"ql-list",attrs:{"value":"bullet"}}),_c('button',{staticClass:"ql-list",attrs:{"value":"ordered"}})])])],1),_c('b-form-group',{attrs:{"label":"Assign To","label-for":"Assign to:"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Assign To")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"task_assignee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.users,"state":errors.length > 0 ? false : null},model:{value:(_vm.task_assignee),callback:function ($$v) {_vm.task_assignee=$$v},expression:"task_assignee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Priority","label-for":"Priority"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Priority")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"task_priority","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.task_priority_options,"state":errors.length > 0 ? false : null},model:{value:(_vm.task_priority),callback:function ($$v) {_vm.task_priority=$$v},expression:"task_priority"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Due Date","label-for":"Due Date"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Due Date")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Due date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control bg-background",staticStyle:{"width":"100%"},attrs:{"placeholder":"Due Date","state":errors.length > 0 ? false : null},model:{value:(_vm.due_date),callback:function ($$v) {_vm.due_date=$$v},expression:"due_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":_vm.isdisable || _vm.due_date == null || _vm.task_assignee == null,"size":"sm"}},[(_vm.isLoading)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v(" Assign Task")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }