<template>
  <!-- blog comment -->
  <b-col id="blogComment" cols="12" class="mt-2">
    <h6 class="section-label">Comments</h6>

    <!-- List of comments -->
    <b-card class="mb-0" v-for="(comment, index) in task.comments" :key="index">
      
        <b-media-body class="border-primary p-1 rounded">
          <div class="d-flex align-items-center">
          <h6 class="font-weight-bolder mb-0 text-capitalize mr-1">
            {{ comment.commentOwner.first_name }}
            {{ comment.commentOwner.last_name }}
            <!-- ({{comment.commentOwner.username}}) -->
          </h6>
             <span style="font-size: 11px"
            >At {{ comment.created_at | formatdate }}</span
          >
          <!-- <b-card-text>At {{ comment.created_at | formatdate }}</b-card-text> -->
          </div>
          <b-card-text class="font-italic">
            {{ comment.comment }}
          </b-card-text>
        </b-media-body>
      
    </b-card>
    <!-- End  of comments -->
    <!-- Start of comment add form -->
     <!-- #default="{ invalid }" -->
    <b-col cols="12" class="mt-2">
      <b-card>
        <validation-observer ref="TaskAddForm">
          <b-form @submit.prevent="addComment">
            <b-row class="align-items-center">
              <b-col sm="9">
                <validation-provider
                  #default="{ errors }"
                  name="comment"
                  rules="required"
                >
                  <b-form-textarea
                    id="comment"
                    v-model="comment"
                    :state="errors.length > 0 ? false : null"
                    name="comment"
                    placeholder="Add your comment here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col sm="3">
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="isDisable"
                  size="sm"
                >
                  <span v-if="isloading"
                    ><b-spinner type="border" small></b-spinner> Please
                    wait</span
                  >
                  <span v-else><feather-icon icon="SendIcon" /> Send</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
    <!-- End of comment add form -->
  </b-col>
  <!--/ blog comment -->
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BCol,
  BRow,
  BMedia,
  BMediaBody,
  BCard,
  BCardText,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BMedia,
    BMediaBody,
    BSpinner,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    // callBack:{
    //     type: Function,
    //     required: true
    // }
  },
  data() {
    return {
      comment: "",
      isloading: false,
      isDisable:false,
    };
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  methods: {
    addComment: function () {
      this.$refs.TaskAddForm.validate().then((success) => {
        this.isloading = true;
        this.isDisable = true;
        let data = {
          comment: this.comment,
          commented_by: this.$store.state.app.user.id,
          task_id: this.task.task_id,
        };
        const options = {
          method: "POST",
          data: data,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "task/comment/",
        };
        var self = this;
        this.$http(options).then((res) => {
          // console.log(res.data)
          console.log(res);
          if (res.data.comment_id) {
            self.task.comments.push(res.data);
            self.comment = "";
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully added your comment",
                icon: "EditIcon",
                variant: "success",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Error while adding your comment",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }
          this.isloading = false;
          this.isDisable = false;
        });
      });
    },
  },
};
</script>
