<template>
    <div class="d-flex flex-column align-items-start justify-content-center w-100">
        <div class="w-100 d-flex align-items-center justify-content-start mb-75">
            <b-avatar v-if="typeof step.order_id != 'undefined' && step.order_id != null" size="md"
                :text="'#' + step.order_id" variant="light-warning" />
            <b-avatar v-else size="md" :text="'#' + index" variant="light-warning" />
            <span class="ml-75 w-100" :style="{ maxHeight: '100px', overflowY: 'auto' }">
                <h5 v-if="typeof step.title != 'undefined' && step.title != null" class="mb-0 font-weight-bolder" v-html="step.title"></h5>
                <h5 v-else class="mb-0 font-weight-bolder" v-html="step.description"></h5>
            </span>
        </div>
        <div class="bg-transperant bg-darken-5 rounded d-flex flex-column align-items-start justify-content-between w-100 mb-75">
            <template v-if="typeof step.uploaded_file != 'undefined' || typeof steps_files[step.filename] != 'undefined'">
                <div class="w-100 d-flex align-items-center justify-content-center" style="overflow:hidden;min-height:530px;">
                    <panZoom :options="{ minZoom: 0.1, maxScale: 5, }" selector=".zoomable" class="w-100">
                        <div class="zoomable d-flex align-items-center justify-content-center">
                            <img v-if="steps_files && steps_files != null" height="480px" width="98%" :ref="step.filename"
                                :src="steps_files[step.filename]" :alt="step.caption"
                                style="cursor:move;object-fit:contain;" />
                            <img v-else height="480px" width="98%" :ref="step.filename"
                                :src="convertFileToImage(step.uploaded_file)" :alt="step.caption"
                                style="cursor:move;object-fit:contain;" />
                        </div>
                    </panZoom>
                </div>
            </template>
          
            <div class="d-flex align-items-center justify-content-start p-1 w-100">
                <p class="mb-0 font-weight-bold" >{{ step.caption }}</p>
            </div>
        </div>
        <!-- <div class="d-flex align-items-center justify-content-start">
            <p class="mb-0 font-weight-bold">{{ step.caption }}</p>
        </div> -->
    </div>
</template>

<script>
import { BAvatar, BButton, VBTooltip, BSpinner } from 'bootstrap-vue';

export default {
    components: {
        BAvatar,
        BButton,
        BSpinner
    },

    directives: {
        'b-tooltip': VBTooltip,
    },


    data() {
        return {
            showEnlargedImage: false,
        };
    },

    props: {
        step: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: false,
            default: 1,
        },
        steps_files: {
            type: Array,
            required: false,
            default: null,
        },
    },

    mounted() {
        console.log('Step', this.step)
    },

    methods: {
        convertFileToImage(file) {
            if (file) {
                return URL.createObjectURL(file)
            }
        },

        toggleEnlargedView() {
            this.showEnlargedViewModal()
        },

        showEnlargedViewModal() {
            this.$refs["enlarged_view_modal"].show();
        },
        hideEnlargedViewModal() {
            this.$refs["enlarged_view_modal"].hide();
        },

    },
};
</script>

<style lang="scss" scoped>
.icon-view {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.skeleton-box {
    display: inline-block;
    height: 1em;
    position: absolute;
    overflow: hidden;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0));
        animation: shimmer 5s infinite;
        content: '';
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}
</style>