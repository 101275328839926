import { render, staticRenderFns } from "./VulnActions.vue?vue&type=template&id=63afff60&scoped=true"
import script from "./VulnActions.vue?vue&type=script&lang=js"
export * from "./VulnActions.vue?vue&type=script&lang=js"
import style0 from "./VulnActions.vue?vue&type=style&index=0&id=63afff60&prod&lang=css"
import style1 from "./VulnActions.vue?vue&type=style&index=1&id=63afff60&prod&lang=scss"
import style2 from "./VulnActions.vue?vue&type=style&index=2&id=63afff60&prod&lang=scss&scoped=true"
import style3 from "./VulnActions.vue?vue&type=style&index=3&id=63afff60&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63afff60",
  null
  
)

export default component.exports