<template>
  <div style="height: inherit">
    <div
      class="app-fixed-search d-flex align-items-center"
      v-if="selectedTasks.length > 0"
    >
      <span
        title="Click to select all"
        style="cursor: pointer; margin-right: 2px"
        class="text-primary"
        @click="selectall()"
        >Select All</span
      >
      <span
        title="Click to clear"
        style="cursor: pointer"
        class="text-primary ml-1"
        @click="clearall()"
      >
        Clear All</span
      >
      <div class="clearfix ml-auto">
        <b-form-select
          v-model="selected_action"
          :options="with_selected_options"
          @change="doBulkAction"
        >
        </b-form-select>
      </div>
    </div>

    <!-- new task component -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="todo-task-list-wrapper list-group scroll-area"
      :key="loading"
    >
      <div class="d-flex justify-content-center mb-1" v-if="loading">
        <b-spinner class="float-right" label="Floated Right" />
      </div>
      <b-row>
        <b-col cols="12" style="overflow-x: auto">
          <table
            class="table table-bordered my-2"
            hover
            responsive
            v-if="!loading"
            :per-page="perPage"
            id="my-table"
          >
            <thead>
              <th>Select</th>
              <th>Name</th>
              <th>Asset</th>
              <th>Status</th>
              <th>Priority</th>
              <th>Assigned To</th>
            </thead>
            <tbody>
              <tr
                v-for="task in tasks"
                :key="task.task_id"
                :class="{ completed: task.task_status == 2 }"
                @click="showSidebar(task)"
              >
                <td>
                  <div class="title-wrapper">
                    <b-form-checkbox
                      :checked="task.task_status == 2"
                      @click.native.stop
                      v-model="selectedTasks"
                      :value="task.task_id"
                    />
                  </div>
                </td>
                <td>
                  <span class="todo-title">{{ task.task_title }}</span>
                </td>
                <td>
                  <router-link
                    :to="'/assets/scan/' + task.asset_profile.asset_id"
                    >{{ task.asset_profile.asset_nickname }}</router-link
                  >
                </td>
                <td>
                  <div class="badge-wrapper">
                    <!-- Task Status -->
                    <b-badge variant="primary" v-if="task.task_status == 0"
                      >Assigned</b-badge
                    >
                    <b-badge variant="danger" v-else-if="task.task_status == 4"
                      >Re-opened</b-badge
                    >
                    <b-badge variant="warning" v-else-if="task.task_status == 1"
                      >In Progress</b-badge
                    >
                    <b-badge variant="success" v-else-if="task.task_status == 2"
                      >Completed</b-badge
                    >
                    <b-badge variant="info" v-else>UnKnown</b-badge>
                  </div>
                </td>
                <td>
                  <div class="badge-wrapper">
                    <!-- Task Priority -->
                    <b-badge variant="primary" v-if="task.task_priority == 1"
                      >Low</b-badge
                    >
                    <b-badge
                      variant="secondary"
                      v-else-if="task.task_priority == 2"
                      >Medium</b-badge
                    >
                    <b-badge
                      variant="warning"
                      v-else-if="task.task_priority == 3"
                      >High</b-badge
                    >
                    <b-badge
                      variant="danger"
                      v-else-if="task.task_priority == 4"
                      >Critical</b-badge
                    >
                    <b-badge variant="info" v-else>UnKnown</b-badge>
                  </div>
                </td>
                <td>
                  <small
                    class="text-nowrap text-primary mr-1"
                    v-if="task.task_assigned_to"
                    >{{ task.task_assigned_to.full_name }}</small
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div class="no-results" :class="{ show: !tasks.length }">
            <h5>No Items Found</h5>
          </div>
          <div class="m-2">
            <div class="row">
              <div class="d-flex align-items-center justify-content-end col-12">
                <b-pagination
                  v-model="currentPage"
                  :numberOfPages="total"
                  :total-rows="rows"
                  :link-gen="linkGen"
                  :per-page="perPage"
                  use-router
                  v-if="!loading"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>
    <!-- end new task component -->

    <!-- Right Sidebar starts -->
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <TaskEditForm
        :task="task"
        :callBack="afterTaskUpdate"
        :closeSidebar="closeSidebar"
      />
    </b-sidebar>
    <b-sidebar
      id="sidebar-task-handler-taskform"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <TaskBulkAssignForm
        :tasks="selectedTasks"
        :callBack="afterTaskBulkUpdate"
        :closeSidebar="closeTaskBulkAssignor"
        :organization="organization"
      />
    </b-sidebar>
  </div>
</template>
<script>
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BBadge,
  BAvatar,
  VBToggle,
  BSidebar,
  BSpinner,
  BPagination,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import TaskEditForm from "./../TaskForm.vue";
import TaskBulkAssignForm from "./../TaskBulkUpdate.vue";
import { avatarText } from "@core/utils/filter";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    VuePerfectScrollbar,
    TaskEditForm,
    VBToggle,
    BSidebar,
    // LeftSideBar,
    BFormSelect,
    vSelect,
    BSpinner,
    TaskBulkAssignForm,
    BPagination,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    tasks: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    callBack: {
      type: Function,
      required: true,
    },
    organization: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      // tasks: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      selected_action: null,
      selectedTasks: [],
      with_selected_options: [
        { value: null, text: "--With Selected--" },
        { value: "reassign", text: "Assign to" },
        { value: "mark_as_closed", text: "Mark as Closed" },
        { value: "mark_as_open", text: "Mark as Open" },
        { value: "mark_as_in_progress", text: "Mark as In Progress" },
        { value: "delete", text: "Delete" },
      ],
      task: {},
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      loading: false,
    };
  },
  created: function () {
    this.load();
  },

  setup() {
    return {
      avatarText,
    };
  },
  methods: {
    load: function () {},
    clearall: function () {
      this.selectedTasks = [];
    },
    selectall: function () {
      // this.selectedTasks =
      this.tasks.forEach((item) => {
        const index = this.selectedTasks.indexOf(item.selectedTasks);
        if (index < 0) this.selectedTasks.push(item.task_id);
      });
    },
    showSidebar: function (taskData) {
      console.log("taskData", taskData);
      this.task = taskData;
      // this.$refs['taskSideBar'].show();
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
      // this.isTaskHandlerSidebarActive = true
    },
    afterTaskUpdate: function () {
      this.callBack();
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
    afterTaskBulkUpdate: function () {
      this.selectedTasks = [];
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler-taskform");
      this.callBack();
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
    closeTaskBulkAssignor: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler-taskform");
    },
    changeStatus: function (status) {
      var self = this;

      let data = {
        action_code: 2,
        status: status,
        tasks: this.selectedTasks,
      };
      this.$http({
        method: "PATCH",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "task/task/bulk-update/",
      }).then((res) => {
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedTasks = [];
          self.callBack();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.loading = false;
      });
    },
    doBulkAction: function () {
      if (!this.selectedTasks.length) {
        alert("Please select some items");
        this.selected_action = null;
      }

      switch (this.selected_action) {
        case "reassign":
          // Action to mitigate
          // Pop up for the task assignee
          this.$root.$emit(
            "bv::toggle::collapse",
            "sidebar-task-handler-taskform"
          );
          break;
        case "mark_as_closed":
          if (confirm("Do you really want to close the selected tasks?")) {
            this.changeStatus(2);
          }
          this.selected_action = null;
          break;
        case "mark_as_in_progress":
          if (
            confirm(
              "Do you really want to mark as false positive the selected tasks?"
            )
          ) {
            this.changeStatus(1);
          }
          this.selected_action = null;
          break;
        case "mark_as_open":
          if (confirm("Do you really want to (re)open the selected issues?")) {
            this.changeStatus(0);
          }
          this.selected_action = null;
          break;

        case "delete":
          if (confirm("Do you really want to delete the selected issues?")) {
            this.changeStatus(9);
          }
          this.selected_action = null;
          break;

        default:
          break;
      }
    },
  },
  filters: {
    smallerCase: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.toLowerCase().trim();
    },
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
.w-60 {
  width: 80% !important;
}
.w-40 {
  width: 20% !important;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>