 <template>
  <validation-observer ref="AssetEditForm">
    <form ref="form" @submit.prevent="addTask">
      <b-form-group label="Task Title" label-for="Task Title">
        <template #label>
          <span>Task Title</span>
          <span class="text-danger font-weight-bold h5" style="margin-left: 3px"
            >*</span
          >
        </template>
        <validation-provider
          #default="{ errors }"
          name="task_title"
          rules="required"
        >
          <b-form-input
            id="task_title"
            :state="errors.length > 0 ? false : null"
            v-model="task_title"
          ></b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Task Description" label-for="Task Description">
        <!-- <b-form-textarea
          id="task_description"
          v-model="task_description"
        ></b-form-textarea> -->
        <quill-editor v-model="task_description" :options="editorOption">
          <div id="toolbar" slot="toolbar">
            <button class="ql-list" value="bullet"></button>
            <button class="ql-list" value="ordered"></button>
          </div>
        </quill-editor>
      </b-form-group>
      <!-- Owner-->
      <b-form-group label="Assign To" label-for="Assign to:">
        <template #label>
          <span>Assign To</span>
          <span class="text-danger font-weight-bold h5" style="margin-left: 3px"
            >*</span
          >
        </template>
        <validation-provider
          #default="{ errors }"
          name="task_assignee"
          rules="required"
        >
          <b-form-select
            v-model="task_assignee"
            :options="users"
            :state="errors.length > 0 ? false : null"
          ></b-form-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <!-- Task Priority-->
      <b-form-group label="Priority" label-for="Priority">
        <template #label>
          <span>Priority</span>
          <span class="text-danger font-weight-bold h5" style="margin-left: 3px"
            >*</span
          >
        </template>
        <validation-provider
          #default="{ errors }"
          name="task_priority"
          rules="required"
        >
          <b-form-select
            v-model="task_priority"
            :options="task_priority_options"
            :state="errors.length > 0 ? false : null"
          ></b-form-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group label="Due Date" label-for="Due Date">
        <template #label>
          <span>Due Date</span>
          <span class="text-danger font-weight-bold h5" style="margin-left: 3px"
            >*</span
          >
        </template>
        <validation-provider
          #default="{ errors }"
          name="Due date"
          rules="required"
        >
          <flat-pickr
            placeholder="Due Date"
            style="width: 100%"
            v-model="due_date"
            class="form-control bg-background"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-button
        type="submit"
        variant="primary"
        :disabled="isdisable || due_date == null || task_assignee == null"
        size="sm"
      >
        <span v-if="isLoading"
          ><b-spinner type="border" small></b-spinner> Please wait</span
        >
        <span v-else> Assign Task</span>
      </b-button>
      <!-- <b-button @click="updateIssue(vuln, 5)">hello</b-button> -->
    </form>
  </validation-observer>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BSpinner,
    flatPickr,
    quillEditor,
  },
  props: {
    asset_id: {
      type: Number,
      required: true,
    },
    vuln_id: {
      type: Number,
      required: false,
    },
    task: {
      type: String,
      required: true,
    },
    organization: {
      type: Number,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    task_type: {
      type: String,
      required: true,
    },
    callBack: {
      type: Function,
      required: true,
    },
    vuln: {
      type: Object,
      required: true,
    },
    assetOwner: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },
      is_staff: this.$store.state.app.user.is_staff,
      task_assignee: null,
      task_title: "",
      task_description: "",
      task_priority: 1,
      //   asset_id:"",
      users: [{ value: null, text: "-- Assign to --" }],
      task_priority_options: [
        { value: 1, text: "Low" },
        { value: 2, text: "Medium" },
        { value: 3, text: "High" },
        { value: 4, text: "Critical" },
      ],
      isLoading: false,
      isdisable: false,
      due_date: null,
    };
  },
  created: function () {
    this.load();
    this.setDefaultPriority();
    // this.setDefaultAssignee();
  },
  methods: {
    load: function () {
      this.task_title = this.task;
      this.task_description = this.description;
      let url = process.env.VUE_APP_BASEURL + "user/users/get-all-users";
      if (this.is_staff) {
        url = url + "?q_org_id=" + this.organization;
      }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        console.log(res.data);
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].id,
            text:
              res.data[key].full_name.charAt(0).toUpperCase() +
              res.data[key].full_name.slice(1),
          };
          // console.log(res.data[key].profile.organization)
          self.users.push(user);
        });
        this.setDefaultAssignee();
      });
    },

    setDefaultPriority: function () {
      // Match vuln.severity with task_priority_options and set as default
      const severityLevel = this.vuln.severity.toLowerCase();
      const matchingOption = this.task_priority_options.find(
        (option) => option.text.toLowerCase() === severityLevel
      );
      if (matchingOption) {
        this.task_priority = matchingOption.value;
      }
    },
    setDefaultAssignee: function () {
      let assetOwnerId = null;
      if (!this.assetOwner) {
        assetOwnerId = this.vuln.asset.assetOwner.id;
        // assetOwnerId = this.vuln.asset.assetOwner
        //   ? this.vuln.asset.assetOwner.id
        //   : null;
      } else {
        assetOwnerId = this.assetOwner ? this.assetOwner : null;
      }

      const matchingUser = this.users.find(
        (user) => user.value === assetOwnerId
      );
      if (matchingUser) {
        this.task_assignee = matchingUser.value;
      }
    },
    addTask: function () {
      this.isdisable = true;
      this.isLoading = true;
      let formData = new FormData();
      formData.append("task_title", this.task_title);
      formData.append("task_description", this.task_description);
      formData.append("asset", this.asset_id);
      formData.append("task_assignor", localStorage.getItem("userid"));
      formData.append("task_assignee", this.task_assignee);
      formData.append("task_type", this.task_type);
      formData.append("task_priority", this.task_priority);
      formData.append("due_date", this.due_date);
      // let data = {
      //     task_title : this.task_title,
      //     task_description : this.task_description,
      //     asset : this.asset_id,
      //     task_assignor: localStorage.getItem('userid'),
      //     task_assignee: this.task_assignee,
      //     task_type: this.task_type,
      //     task_priority: this.task_priority
      // }
      if (this.vuln_id) formData.append("vuln_id", this.vuln_id);
      const options = {
        method: "POST",
        data: formData,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "task/task/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data.task_id) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully added the task",
              icon: "EditIcon",
              variant: "success",
            },
          });
          // this.updateIssue(this.vuln, 5);
          this.callBack();
        }
        this.isLoading = false;
        this.isdisable = false;
      });
    },
    // updateIssue: function (vuln, status) {
    //   let data = {
    //     status: status,
    //     severity: this.severity,
    //     // remedy: "Test"
    //   };
    //   const options = {
    //     method: "PATCH",
    //     headers: { "content-type": "application/json" },
    //     data: data,
    //     url: process.env.VUE_APP_BASEURL + "asset/vulns/" + vuln.id + "/",
    //   };
    //   this.$http(options).then((res) => {
    //     this.callBack();
    //     this.vuln.status = status;
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: "Vulnerability status updated successfully",
    //         icon: "editIcon",
    //         variant: "success",
    //       },
    //     });
    //   });
    // },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.bg-background {
  background: #fff !important;
}
[dir] .dark-layout .bg-background {
  // background: #283046 !important;
  background: #253e42 !important;
  border-color: #404656;
}
</style>