<template>
  <!-- blog comment -->
  <b-col id="blogComment" cols="12" class="mt-2">
    <h6 class="section-label">Comments</h6>
    <!-- {{orguser}} -->

    <!-- List of comments -->
    <b-card v-for="(comment, index) in comments" :key="index">
      <b-media-body class="border-primary p-1 rounded">
        <b-button
          variant="flat-danger"
          title="Delete Comment"
          class="btn-icon float-right"
          @click="deleteComment(comment.id)"
          v-if="comment.commented_by == $store.state.app.user.id"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
        <div class="d-flex align-items-center">
          <h6 class="font-weight-bolder mb-0 text-capitalize mr-1">
            {{ comment.commentOwner.first_name }}
            {{ comment.commentOwner.last_name }}
            <!-- ({{comment.commented_by}}) -->
          </h6>
          <span style="font-size: 11px"
            >At {{ comment.commented_at | formatdate }}</span
          >
        </div>

        <b-card-text class="font-italic">
          {{ comment.comment_text }}
        </b-card-text>
      </b-media-body>
    </b-card>
    <!-- End  of comments -->
    <!-- Start of comment add form -->
    <b-col cols="12" class="mt-2">
      <b-card>
        <validation-observer ref="TaskAddForm">
          <b-form @submit.prevent="addComment">
            <b-row class="align-items-center">
              <b-col sm="10">
                <Mentionable
                  :keys="['@']"
                  :items="participants"
                  offset="6"
                  @apply="tagedUsers($event)"
                >
                  <b-form-input
                    id="comment"
                    placeholder="Start a new conversation.Type @ to mention someone."
                    v-model="comment"
                    class="input-comment"
                    @keyup="getMention"
                  />
                  <template #item-@="{ item }">
                    <div class="user">
                      <a :value="item.id">{{ item.text }}</a>
                    </div>
                  </template>
                </Mentionable>
              </b-col>
              <b-col sm="2">
                <b-button
                  type="submit"
                  size="sm"
                  variant="primary"
                  :disabled="isDisable"
                >
                  <span v-if="isloading"
                    ><b-spinner type="border" small></b-spinner> Please
                    wait</span
                  >
                  <span v-else><feather-icon icon="SendIcon" /> Send</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
    <!-- End of comment add form -->
  </b-col>
  <!--/ blog comment -->
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { Mentionable } from "vue-mention";
import {
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BCol,
  BRow,
  BMedia,
  BMediaBody,
  BCard,
  BCardText,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import "floating-vue/dist/style.css";
export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BMedia,
    BMediaBody,
    Mentionable,
    BSpinner,
  },
  props: {
    vuln_id: {
      type: Number,
      required: true,
    },
    organization: {
      type: Number,
      required: true,
    },
    activeTab: {
      type: Number,
      required: true,
    },
    // callBack:{
    //     type: Function,
    //     required: true
    // }
  },
  data() {
    return {
      comment: "",
      comments: [],
      user_options: [],
      participants: [],
      tagedUserlist: [],
      org_id: "",
      orguser: [],
      users: [
        {
          value: "cat",
          label: "Mr Cat",
        },
        {
          value: "dog",
          label: "Mr Dog",
        },
      ],
      isloading: false,
      isDisable: false,
    };
  },
  // created: function () {
  //   this.load();
  // },
  created: function () {
    if (this.vuln_id != null) {
      this.load();
    }
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  watch: {
    $route(to, from) {
      this.getUsers();
    },
    userOptions() {
      this.getUsers();
    },
    activeTab(newValue, oldValue) {
      if (newValue === 3) {
        this.load();
      }
    },
    // vuln_id(value) {
    //   this.load();
    // },
  },
  methods: {
    tagedUsers(value) {
      const tagedUserlist = [];
      console.log("mentioned userid" + this.tagedUserlist);
      this.tagedUserlist.push(value.id);
    },
    getMention: function (event) {
      if (event.keyCode === 50) {
        this.getUsers();
        // alert('@ was pressed');
        // this.getUsers();
      }
      if (event.code === "Backspace" && this.comment.endsWith("@")) {
        this.tagedUserlist.pop();
      }
    },
    getUsers: function () {
      this.user_options = [];
      this.participants = [];
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "user/users/get-all-org-users?q_org_id=" +
          this.organization,
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          console.log(this.orguser, "users");
          // this.orguser = res.data
          // self.participants = res.data;
          res.data.map(function (value, key) {
            let user = {
              id: res.data[key].id,
              value: res.data[key].first_name,
              text: res.data[key].first_name,
            };
            self.participants.push(user);
          });

          // for (let a of Object.entries(res.data)) {
          //   let users = {
          //     id: a.id,
          //     value: a.id,
          //     text: a.first_name,
          //   };

          //   self.participants.push(users);
          // }
          // res.data.map(function (value, key) {
          //   let user = {
          //     id: key,
          //     value: key,
          //     label: value,
          //   };
          //   let users = {
          //     value: key,
          //     text: key,
          //   };
          //   self.user_options.push(user);
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vuln-comment?vuln_id=" +
          this.vuln_id,
      };
      var self = this;
      this.$http(options).then((res) => {
        self.comments = res.data.results;
      });
    },
    addComment: function () {
      this.$refs.TaskAddForm.validate().then((success) => {
        this.isloading = true;
        this.isDisable = true;
        let data = {
          comment_text: this.comment,
          commented_by: this.$store.state.app.user.id,
          vuln_id: this.vuln_id,
          mentions: this.tagedUserlist,
        };
        const options = {
          method: "POST",
          data: data,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "asset/vuln-comment/",
        };
        var self = this;
        this.$http(options).then((res) => {
          // console.log(res.data)
          console.log(res);
          if (res.data.id) {
            self.comments.push(res.data);
            self.comment = "";
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully added your comment",
                icon: "EditIcon",
                variant: "success",
              },
            });
            self.tagedUserlist = [];
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Error while adding your comment",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }
          this.isloading = false;
          this.isDisable = false;
        });
      });
    },
    deleteComment: function (comment_id) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/vuln-comment/" +
          comment_id +
          "?vuln_id=" +
          this.vuln_id,
      };
      var self = this;
      this.$http(options).then((res) => {
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully deleted your comment",
            icon: "EditIcon",
            variant: "success",
          },
        });
        self.load();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
[dir] .card-body {
  padding: 0.5rem;
}
</style>
<style>
.v-tooltip {
  background: #000 !important;
}
.input-comment {
  border: none;
  border-bottom: 1px solid blue;
  border-radius: 0px;
}
.mention-selected {
  background: rgb(192, 250, 153) !important;
}
.keyresult-list .card {
  margin-bottom: 0px !important;
}
.mention-item {
  padding: 4px 10px !important;
  border-radius: 4px !important;
}
</style>