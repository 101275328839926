var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[(_vm.$route.params.type != 'deleted-task')?_c('div',[_c('b-button',{attrs:{"size":"sm","variant":_vm.task.task_status == 2 ? 'outline-success' : 'outline-secondary'},on:{"click":function($event){return _vm.markAsComplete()}}},[_vm._v(" "+_vm._s(_vm.task.task_status == 2 ? "Completed" : "Mark Complete")+" ")]),(_vm.edit_allowed)?_c('b-button',{staticClass:"ml-1",attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return _vm.deleteTask()}}},[_vm._v(" Delete ")]):_vm._e()],1):_vm._e(),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){return _vm.closeSidebar()}}})],1)]),_c('b-card-code',{attrs:{"title":"Update Task"}},[_c('b-badge',{staticClass:"my-1",attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.task.task_type)+" ")]),_c('h5',[_vm._v(" Asset: "),(_vm.task.asset === null)?_c('span',[_vm._v(_vm._s(_vm.task.asset_name))]):(_vm.task.asset_profile.is_deleted)?_c('span',[_vm._v(_vm._s(_vm.task.asset_name))]):_c('router-link',{attrs:{"to":'/assets/scan/' + _vm.task.asset_profile.asset_id}},[_vm._v(_vm._s(_vm.task.asset_profile.asset_name))])],1),_c('h5',[_vm._v("Assigned By: "+_vm._s(_vm.task.task_assigned_by.full_name))]),(
        _vm.task.vuln_id &&
        _vm.task.asset != null &&
        _vm.task.asset_profile != null &&
        !_vm.task.asset_profile.is_deleted
      )?_c('h5',[_vm._v(" Vulnerability: "),_c('router-link',{attrs:{"to":{ path:'/vulnerability/detail/' + _vm.task.vuln_id, query: { isTaskDetails: true } }}},[_vm._v(" Go to Details")])],1):(_vm.task.asset_profile != null && !_vm.task.asset_profile.is_deleted)?_c('h5',[_vm._v(" Vulnerability: "),_c('router-link',{attrs:{"to":'/vulnerability/detail/' + _vm.task.vuln_id}},[_vm._v(" Go to Details")])],1):_vm._e(),_c('validation-observer',{ref:"OrgAddForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.updateTask($event)}}},[_c('b-form-group',{attrs:{"label":"Task Title","label-for":"Task Title"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Task Title")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"task_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"task_title","state":errors.length > 0 ? false : null,"name":"task_title","disabled":!_vm.edit_allowed},model:{value:(_vm.task_title_local),callback:function ($$v) {_vm.task_title_local=$$v},expression:"task_title_local"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Task Description","label-for":"Task Description"}},[_c('b-form-textarea',{attrs:{"id":"task_title","name":"task_description","disabled":!_vm.edit_allowed},model:{value:(_vm.task_description_local),callback:function ($$v) {_vm.task_description_local=$$v},expression:"task_description_local"}})],1),_c('b-form-group',{attrs:{"label":"Assigned To","label-for":"Assigned to:"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Assigned To")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"task_assignee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.users,"state":errors.length > 0 ? false : null,"disabled":!_vm.reassignment_allowed},model:{value:(_vm.task_assignee_local),callback:function ($$v) {_vm.task_assignee_local=$$v},expression:"task_assignee_local"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Priority","label-for":"Priority"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Priority")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"task_priority_local","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.task_priority_options,"state":errors.length > 0 ? false : null,"disabled":!_vm.reassignment_allowed},model:{value:(_vm.task_priority_local),callback:function ($$v) {_vm.task_priority_local=$$v},expression:"task_priority_local"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Status","label-for":"Status"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Status")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"task_status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.task_statuses,"state":errors.length > 0 ? false : null,"disabled":!_vm.reassignment_allowed},model:{value:(_vm.task_status_local),callback:function ($$v) {_vm.task_status_local=$$v},expression:"task_status_local"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(
            _vm.task_status_local == 2 && _vm.task.task_type == 'Request_for_retest'
          )?_c('div',[_c('h6',{staticClass:"text-secondary"},[_vm._v(" Vulnerability Name: "),_c('b-badge',{staticClass:"my-1",attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.vuln_items)+" ")])],1),_c('b-form-group',{attrs:{"label":"Vulnerability Status","label-for":"Status"}},[_c('b-form-select',{attrs:{"options":_vm.vuln_status_options},on:{"change":function($event){return _vm.updateIssue()}},model:{value:(_vm.vuln_status),callback:function ($$v) {_vm.vuln_status=$$v},expression:"vuln_status"}})],1)],1):_vm._e(),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid,"size":"sm"}},[(_vm.isloading)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v(" Update")])])],1)]}}])})],1),_c('TaskComments',{attrs:{"task":_vm.task_o}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }