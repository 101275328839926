<template >
  <div>
    <div v-if="logs.length">
      <b-list-group-item
        class="bg-transparent border-primary"
        v-for="listItem in logs"
        :key="listItem.log_id"
        tag="li"
      >
        <div class="d-flex">
          <!-- <b-avatar :text="listItem.filename.slice(0,2)" /> -->
          <div class="ml-15 w-100">
            <div class="d-flex justify-content-end">
              <small class="ml-auto text-primary">
                {{ listItem.activity_time | formatdate }}</small
              >
            </div>
            <b-card-text class="font-weight-bold mb-0">
              <!-- <span class="text-primary">{{ listItem.user_email }}</span> -->
              <p v-html="listItem.log_message"></p>
            </b-card-text>
          </div>
        </div>
      </b-list-group-item>
    </div>
    <div v-else>
      <div
        class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
      >
        <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
        <h3 class="font-weight-bolder">No Data Found</h3>
      </div>
    </div>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BListGroupItem, BCardText } from "bootstrap-vue";
import moment from "moment";

export default {
  components: {
    BCardCode,
    BCardText,
    BListGroupItem,
  },
  props: {
    vuln_id: {
      type: Number,
      required: false,
    },
    activeTab: {
      type: Number,
      required: true,
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  data() {
    return {
      logs: [],
    };
  },
  created: function () {
    if (this.vuln_id != null && this.activeTab == 1) {
      this.load();
    } 
  },
  watch: {
    activeTab(newValue, oldValue) {
      if (newValue === 1) {
        this.load();
      }
    },
    // vuln_id() {
    //   if (this.activeTab == 1) {
    //     this.load();
    //   }
    // },
  },
  methods: {
    load: function () {
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "logs/v1/logs/vulnerability-logs?vulnerability=" +
          this.vuln_id,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        self.logs = res.data.results;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
[dir].dark-layout .list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: #283046 !important;
  border: 1px solid rgb(68 101 129) !important;
  /* border-radius: 5px; */
}
</style>