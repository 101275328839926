<template >
  <div>
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeSidebar()"
        />
      </div>
    </div>
    <b-card-code title="Assign">
      <!-- form -->
      {{ tasks.length }} tasks selected.
      <validation-observer ref="OrgAddForm" #default="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent="updateTask">
          <!-- Owner-->
          <b-form-group label="Assigned To" label-for="Assign to:">
            <validation-provider
              #default="{ errors }"
              name="task_assignee"
              rules="required"
            >
              <b-form-select
                v-model="assignee"
                :options="users"
                :state="errors.length > 0 ? false : null"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button type="submit" variant="primary" :disabled="invalid">
            Assign
          </b-button>
        </b-form>
      </validation-observer>
    </b-card-code>
  </div>
</template>
        
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BBadge,
  BSidebar,
  BForm,
  BButton,
  VBToggle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BBadge,
    BSidebar,
    VBToggle,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  // model: {
  //   prop: 'isTaskHandlerSidebarActive',
  //   event: 'update:is-task-handler-sidebar-active',
  // },
  props: {
    tasks: {
      type: Array,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
    callBack: {
      type: Function,
      required: true,
    },
    organization: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      users: [{ value: null, text: "-- Assigned to --" }],
      assignee: null,
      is_staff: this.$store.state.app.user.is_staff,
    };
  },
  watch: {
    tasks(value) {
      this.users = [];
      let url = process.env.VUE_APP_BASEURL + "user/users/get-all-users";
      if (this.is_staff) {
        url = url + "?q_org_id=" + this.organization;
      }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].id,
            text: res.data[key].full_name.charAt(0).toUpperCase() + res.data[key].full_name.slice(1),
          };
          self.users.push(user);
        });
      });
    },
  },
  methods: {
    updateTask: function () {
      let data = {
        tasks: this.tasks,
        action_code: 1,
        assignee: this.assignee,
      };
      const options = {
        method: "PATCH",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "task/task/bulk-update/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully updated the tasks",
              icon: "EditIcon",
              variant: "success",
            },
          });
          //   self.closeSidebar();
          self.callBack();
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
</style>
<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}
</style>