<template>
    <div class="d-flex flex-row align-items-center justify-content-between w-100" style="height: 190px">
        <b-modal v-if="typeof step.uploaded_file != 'undefined' || typeof steps_files[step.filename] != 'undefined'"
            ref="enlarged_view_modal" hide-footer :title="step.caption" header-close-variant="danger" size="xl">
            <div class="w-100" style="overflow:hidden;height:85vh;">
                <panZoom :options="{ minZoom: 0.1, maxScale: 5, }" selector=".zoomable">
                    <div class="zoomable">
                        <img v-if="steps_files && steps_files != null" height="100%" width="100%" :ref="step.filename"
                            :src="steps_files[step.filename]" :alt="step.caption" style="cursor:move;" />
                        <img v-else height="100%" width="100%" :ref="step.filename"
                            :src="convertFileToImage(step.uploaded_file)" :alt="step.caption" style="cursor:move;" />
                    </div>
                </panZoom>
            </div>

        </b-modal>

        <div @click="toggleEnlargedView" class="d-flex align-items-center justify-content-center bg-transperant cursor-pointer"
            style="width: 30%;height:100%;position:relative;">
            <template v-if="typeof step.uploaded_file != 'undefined' || typeof steps_files[step.filename] != 'undefined'">
                <div class="icon-view">
                    <b-avatar rounded size="md" variant="light" class="m-50 shadow-sm">
                        <feather-icon icon="ExternalLinkIcon" class="text-dark" />
                    </b-avatar>
                </div>

                <img v-if="steps_files && steps_files != null" height="190px" width="100%" :ref="step.filename"
                    :src="steps_files[step.filename]" :alt="step.caption" />
                <img v-else height="190px" width="100%" :ref="step.filename" :src="convertFileToImage(step.uploaded_file)"
                    :alt="step.caption" />
            </template>
            <template
                v-else-if="typeof step.uploaded_file == 'undefined' && step.filename == null || typeof step.filename == 'undefined' || step.filename.includes('no_file')">
                <div class="d-flex flex-column justify-content-center align-items-center w-100 p-1"
                    style="position:relative;">
                    <img height="80px" width="80px" :ref="step.filename" src="/no-image.png" :alt="step.caption" />
                    <h5 class="font-weight-bolder mt-50 mb-25">No Image</h5>
                    <p class="font-weight-bold text-center">Upload to view the image.
                    </p>
                </div>
            </template>
            <template v-else>
                <div class="d-flex justify-content-center align-items-center w-100" style="position:relative;">
                    <span class="skeleton-box bg-secondary bg-lighten-5 d-flex align-items-center justify-content-center"
                        style="width:100%;height:190px;">
                        <img height="100px" width="100px" :ref="step.filename" src="/no-image.png" :alt="step.caption" />
                    </span>
                    <b-spinner label="Loading..." />
                </div>
            </template>
        </div>
        <div class="d-flex flex-column align-items-start justify-content-between py-1 px-1 h-100" style="width: 70%;">
            <div class="d-flex flex-row align-items-start justify-content-between w-100" :style="{ maxHeight: '100px', overflowY: 'auto' }">
                <div class="d-flex flex-column align-items-start justify-content-start">
                    <h5 v-if="typeof step.title != 'undefined' && step.title != null" class="mb-50 font-weight-bolder" v-html="step.title" ></h5>
                    <h5 v-else class="mb-50 font-weight-bolder" v-html="step.description"></h5>
                    <p class="font-weight-bold">{{ step.caption }}</p>
                </div>
                <b-avatar v-if="typeof step.order_id != 'undefined' && step.order_id != null" size="lg"
                    :text="'#' + step.order_id" variant="light-warning" />
                <b-avatar v-else size="lg" :text="'#' + index" variant="light-warning" />
            </div>
            <div class="d-flex flex-row align-items-center justify-content-between w-100">
                <div style="width: 69%;">
                    <!-- <p v-b-tooltip.top.hover.v-info :title="step.filename" class="mb-0 text-truncate mr-50">{{ step.filename
                    }}</p> -->
                </div>
                <div class="d-flex align-items-center justify-content-end" style="max-width:30%;min-width:268px;">
                    <b-button v-if="editSteps != null" size="sm" variant="info" class="mr-50"
                        @click="editSteps(step)"><feather-icon icon="EditIcon" class="mr-50" /><span
                            class="align-middle">Edit</span></b-button>
                    <b-button v-if="gotoDelete != null" size="sm" variant="danger" @click="gotoDelete(
                            step.filename,
                            step.order_id,
                            step.title
                        )"><feather-icon icon="TrashIcon" class="mr-50" /><span
                            class="align-middle">Delete</span></b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BAvatar, BButton, VBTooltip, BSpinner } from 'bootstrap-vue';

export default {
    components: {
        BAvatar,
        BButton,
        BSpinner
    },

    directives: {
        'b-tooltip': VBTooltip,
    },


    data() {
        return {
            showEnlargedImage: false,
        };
    },

    props: {
        step: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: false,
            default: 1,
        },
        steps_files: {
            type: Array,
            required: false,
            default: null,
        },
        editSteps: {
            type: Function,
            required: false,
            default: null,
        },
        gotoDelete: {
            type: Function,
            required: false,
            default: null,
        },
    },

    mounted() {
        console.log('Step', this.step)
    },

    methods: {
        convertFileToImage(file) {
            if (file) {
                return URL.createObjectURL(file)
            }
        },

        toggleEnlargedView() {
            this.showEnlargedViewModal()
        },

        showEnlargedViewModal() {
            this.$refs["enlarged_view_modal"].show();
        },
        hideEnlargedViewModal() {
            this.$refs["enlarged_view_modal"].hide();
        },

    },
};
</script>

<style lang="scss" scoped>
.icon-view {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.skeleton-box {
    display: inline-block;
    height: 1em;
    position: absolute;
    overflow: hidden;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0));
        animation: shimmer 5s infinite;
        content: '';
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}
</style>